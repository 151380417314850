
  import { defineComponent, inject, reactive, Ref, ref, watch, watchEffect } from 'vue'
import { api } from '@/api/useAxios'
import { getUserId } from '@/utils/auth';
import { Toast } from 'vant';
import { list, QryCommentParam } from '@/api/comment';
import { FetchFunction, useLoadList } from '@/utils/useLoadList';
import { PageResponse } from 'momai';

interface Comment {
  id: number ;
  userId: string;
  userName: string;
  parentUserId: string;
  parentUserName: string;
  info: string;
  avatar: string;
  createTime: string
}
const createCommentProps = (): Comment=> ({
  id: -1,
  userId: '',
  userName: '',
  parentUserId: '',
  parentUserName: '',
  info: '',
  avatar: '',
  createTime: ''
})


const useLoadListEffect = (qryParam: QryCommentParam) => {
  const totalNum = ref(-1)
  const commentList = ref<Comment[]>([])
  // commentList.value = []
  const fetch: FetchFunction = (pageData, loadEnd) => {
    const { id, pjType } = qryParam
    list({ id, pjType, pageData })
      .then(({ records, total }: PageResponse<Comment>) => {
        const result = records?.map((item: any) => {
          // 评论数据
          const resp: Comment = reactive(createCommentProps())
          resp.id = item.id
          resp.parentUserId = item.parentUserId
          resp.parentUserName = item.parentUserName
          resp.userId = item.userId
          resp.userName = item.userName
          resp.info = item.info
          resp.avatar = item.avatar
          resp.createTime = item.createTime
          return resp
        })
        totalNum.value = total
        commentList.value.push(...result)
        loadEnd()
      })
  }
  const { loadMore, finished, loading } = useLoadList({
    fetch,
    total: totalNum,
    list: commentList
  })
  return { loadMore, finished, loading, commentList, totalNum }
}

export default defineComponent({
  name: 'CommentList',
  props: {
    ordId: {
      type: Number,
      required: true
    },
    ordType: {
      type: Number,
      required: true
    },
  },
  emits:['update:show'],
  setup: (props, { emit }) => {
    const placeholder = ref('评论')
    const info = ref('')
    let comment = reactive<Comment>(createCommentProps());
    const subTag = ref(false)
    const showTag = ref(true)
    const replyParam = {
      "info": "",
      "status": "0",
      "type": props.ordType, // 评论类型,0：课程，1：作业，2：评论，3：活动 ,4:打卡
      "ordId": props.ordId,
      "parentId": -1, // 回复评论的id
      "userId": getUserId()
    }

    const qryParams: QryCommentParam = {
      id: props.ordId,
      pjType: props.ordType + '',
      pageData: {
        current: 1,
        size: 10
      }
    }
    let { loadMore, finished, loading, commentList, totalNum } = useLoadListEffect(qryParams)

    // 查看更多评价
    const readMore = () => {
      showTag.value = true
    }

    const show = inject<Ref>('commentTag')!
    watch(show, ()=> {
      showTag.value = true
    })

    // 回复评论提示
    // 如果有parentUserId则不可回复
    const handleReply = (index: number) => {
      comment = commentList.value[index]
      //if (comment.parentUserId) return;
      placeholder.value = `回复${comment.userName}:`
    }

    // 回复评论提交
    const submitReply = () => {
      replyParam.info = info.value
      replyParam.parentId = comment.id
      replyParam.userId = comment.userId

      api.post('/mmpinglun/save', replyParam).then( resp => {
        console.log(9999)
        Toast.success("回复成功，等待审核之后，正常展示")
        info.value = ''
        placeholder.value = '评论'
      })
    }

    // 评论提交
    const submitComment = () => {
      const commentParam = {
        info: info.value,
        status: "0",
        type: props.ordType,
        ordId: props.ordId,
        userId: getUserId()
      }
      api.post('/mmpinglun/save', commentParam).then( resp => {
        console.log(8888)
        loadMore
        Toast.success("评论成功，等待审核之后，正常展示")
        info.value = ''
        placeholder.value = '评论'
      })
    }

    // 提交评论
    // 1. 回复评论
    // 2. 提交评论
    const submit = () => {
        if (!info.value) return
        replyParam.parentId? submitReply(): submitComment()
    }

    // 关闭重制
    const toClose = () => {
      placeholder.value = '评论'
      // 初始化 回复提交参数
      comment.id = -1
      comment.userId = ''
      comment.userName = ''
    }


    const onInput = () => {
      subTag.value = info.value? true: false
    }

    return {
      commentList,
      readMore,
      placeholder,
      handleReply,
      info,
      submit,
      toClose,
      onInput,
      subTag,
      loadMore,
      finished,
      loading,
      totalNum,
      showTag
    }
  }
})
