import { createModuleApi, Page, PageResponse } from 'momai'

interface QryCommentParam {
  id: number;
  pjType: string;
  pageData: Page;
}
const createCommentParam = (): QryCommentParam => ({
  id: -1,
  pjType: '',
  pageData: {
    current: 1,
    size: 10
  }
})

const commentApi = <T>() => createModuleApi<T>('mmpinglun')

const list = ({ id, pjType, pageData }: QryCommentParam) => commentApi<PageResponse<any>>()({
  subUrl: 'getPjHuiFuPageInfoNew',
  method: 'post',
  params: {
    ...pageData,
    id,
    pjType
  }
})
export { list, QryCommentParam, createCommentParam }
